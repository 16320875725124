import React from "react";
import { Accordion } from "react-bootstrap";

function SectionPreguntas(props) {
  const preguntasRespuestas = [
    {
      pregunta: "¿Cómo puedo agendar una consulta de nutrición?",
      respuesta:
        "Para agendar una consulta nutricional, puedes hacerlo a través de nuestro sitio web, en el apartado 'NUESTROS SERVICIOS', donde aparecen los distintos servicios que ofrecemos.",
    },
    {
      pregunta: "¿Qué tipo de planificaciones alimentarias ofrecen?",
      respuesta:
        "Ofrecemos planes de alimentación personalizados basados en tus necesidades y objetivos. Estos planes pueden incluir recomendaciones para mejorar tu relación con la comida, alimentación consciente, recetarios saludables y materiales digitales de regalo.",
    },
    {
      pregunta:
        "¿Cuál es la diferencia entre la consulta nutricional por videollamada y la consulta nutricional por formulario?",
      respuesta:
        "La diferencia entre ambos servicios recae en la manera en la que nos comunicamos. La videollamada es una modalidad en vivo desde cualquiera de tus dispositivos, mientras que la modalidad formulario no requiere videollamada y puedes completarlo a tu ritmo.",
    },
    {
      pregunta: "¿Cuánto tiempo dura una consulta de nutrición?",
      respuesta:
        "Nuestras consultas de nutrición duran 60 minutos por videollamada, mientras que la opción de formulario tiene un máximo de 24 horas para ser respondida y enviada. Los seguimientos duran aproximadamente 30 minutos.",
    },
    {
      pregunta:
        "¿Cómo recibiré el link para unirme a la sesión de mi consulta?",
      respuesta:
        "Te enviaremos un email 10 minutos antes de la consulta con el enlace para unirte a la videollamada. Si no recibes el link, puedes contactarnos por Instagram (@Tunutriamiga_) para recibir asistencia.",
    },
    {
      pregunta: "¿Cómo puedo preparar comidas saludables en poco tiempo?",
      respuesta:
        "Te proporcionaremos recetas sencillas y rápidas que sean nutritivas y fáciles de preparar, ajustadas a tus necesidades.",
    },
    {
      pregunta:
        "¿Atiendes casos especiales como embarazadas, vegetarianos, o pacientes con alguna condición de salud?",
      respuesta:
        "Sí, pero te recomendamos revisar nuestro apartado 'ÁREAS FUERA DE NUESTRO ENFOQUE' para verificar si tu caso está cubierto.",
    },
    {
      pregunta: "¿Qué tan seguido debo tener consultas de seguimiento?",
      respuesta:
        "La frecuencia de las consultas de seguimiento dependerá de tus objetivos de salud y tu progreso. Te recomendaremos una frecuencia adecuada al final de la sesión.",
    },
    {
      pregunta: "¿Qué pasa si mi pago falla?",
      respuesta:
        "Si tienes problemas con el pago, puedes comunicarte conmigo a través de mi correo electrónico o por Instagram, y juntos resolveremos cualquier inconveniente.",
    },
  ];

  return (
    <section id="section_preguntas" className="py-5" style={sectionStyle}>
      <div className="container py-4">
        <div className="row gy-4">
          <div className="col-12">
            <h2
              className="display-5 fw-bold text-center mb-4"
              style={titleStyle}
            >
              Preguntas frecuentes
            </h2>
          </div>
          <div className="col-12">
            <Accordion>
              {preguntasRespuestas.map((p, i) => (
                <Accordion.Item key={i} eventKey={i} style={accordionItemStyle}>
                  <Accordion.Header>
                    <p className="fw-bold mb-0" style={preguntaStyle}>
                      {p.pregunta}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body style={respuestaStyle}>
                    {p.respuesta}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}

// Estilos personalizados
const sectionStyle = {
  backgroundColor: "#EAF8E6", // Fondo suave y calmado
};

const titleStyle = {
  color: "#343a40",
};

const accordionItemStyle = {
  borderRadius: "10px",
  border: "none",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Sombra suave para darle profundidad
  marginBottom: "10px", // Espacio entre preguntas
};

const preguntaStyle = {
  fontSize: "1.1rem",
  color: "#28a745", // Color verde para mantener la consistencia
};

const respuestaStyle = {
  backgroundColor: "#fff",
  padding: "15px",
  fontSize: "1rem",
  lineHeight: "1.6",
};

export default SectionPreguntas;
