import React from "react";

function TerminosPage(props) {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Términos y condiciones de uso</h1>

      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h3>Introducción</h3>
          <p>
            Bienvenido/a a "tuNutriAmiga", la aplicación para un servicio de
            nutrición online. Al acceder y utilizar nuestra plataforma, acepta
            los siguientes términos y condiciones de uso. Lea con atención.
          </p>

          <h3>Creación de cuenta</h3>
          <p>
            Para hacer uso de la aplicación, deberá crear una cuenta
            proporcionando información básica como su correo electrónico y país
            de residencia. La información proporcionada deberá ser veraz y
            precisa.
          </p>

          <h3>Agendar citas y pagos</h3>
          <p>
            La aplicación le permite agendar citas para el servicio de nutrición
            online para lo cual deberá realizar un pago en línea para confirmar
            la reserva. Los pagos serán procesados a través de un tercero y
            estarán sujetos a sus términos y condiciones.
          </p>

          <h3>Cancelaciones y devoluciones</h3>
          <p>
            El usuario podrá cancelar una cita hasta 24 horas antes de la fecha
            acordada y se le emitirá la devolución del dinero correspondiente.
            Pasado este plazo, no se permitirán cancelaciones ni se realizará la
            devolución del dinero.
          </p>

          <h3>Protección de datos personales</h3>
          <p>
            La información proporcionada por el usuario será tratada de manera
            confidencial y solo se utilizará para la prestación del servicio
            ofrecido por "tuNutriAmiga". Para conocer más sobre nuestra política
            de privacidad, por favor consulte el siguiente enlace:
            www.tunutriamiga.cl/privacidad.
          </p>

          <h3>Propiedad intelectual</h3>
          <p>
            La aplicación y todo su contenido (incluyendo textos, imágenes,
            gráficos, videos, etc.), así como la marca y logotipo son propiedad
            de "tuNutriAmiga" y están protegidos por las leyes de propiedad
            intelectual. Queda prohibida cualquier reproducción, distribución,
            modificación o uso sin autorización previa por escrito.
          </p>

          <h3>Modificaciones de los términos y condiciones</h3>
          <p>
            "tuNutriAmiga" se reserva el derecho de modificar los términos y
            condiciones de uso en cualquier momento sin necesidad de previo
            aviso. La continuación del uso de la aplicación tras la publicación
            de las modificaciones constituye la aceptación de los nuevos
            términos y condiciones.
          </p>

          <p className="text-center">
            Agradecemos el uso de nuestra aplicación y le deseamos una excelente
            experiencia en el servicio de nutrición online.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TerminosPage;
