import axios from "axios";
import { getToken, apiUrl } from "api/AuthContextProvider";
import moment from "moment";

const instanceConfig = {
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "x-access-token": `${getToken()}`,
  },
};

export const api = axios.create(instanceConfig);

api.interceptors.request.use((config) => {
  if (config.method === "get") {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

export const getlist_opciones = (fecha) => {
  return api
    .get("getlist_opciones", {
      params: { fecha: moment(fecha).add(10, "hours").unix() },
    })
    .then((response) => {
      return response.data; // ["09:00", "10:30", "12:00", "15:00", "16:30", "18:00"]
    });
};
