import { AuthContext } from "api/AuthContextProvider";
import { useContext, useState } from "react";
import { Button, Form, Modal, Spinner, Tab, Tabs } from "react-bootstrap";

import { es } from "date-fns/locale";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { getlist_opciones } from "api";
import { crearUrlFlow, crear_reserva } from "services/agendaService";
import { tfail, tsuccess } from "tools";
import { validateEmail } from "../utils";

function ModalAgendar({ tipo: tipoReserva }) {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function disableDays(day) {
    const diasNoPermitidos = [0, 2, 4, new Date().getDay() + 1];
    return diasNoPermitidos.includes(day.getDay());
  }

  const disabledDays = [
    { from: new Date(2020, 4, 18), to: new Date() },
    disableDays,
  ];
  const hiddenDays = [];

  const [selectedDay, setSelectedDay] = useState(false);
  const [opcionesHoras, setOpcionesHoras] = useState([]);
  const [selectedHora, setSelectedHora] = useState(0);
  const [inputEmail, setInputEmail] = useState("");
  const [inputNombre, setInputNombre] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [keyTab, setKeyTab] = useState("home");
  const [isLoadingHoras, setLoadingHoras] = useState(false);
  const [confirmarDisabled, setConfirmarDisabled] = useState(true);

  // Estados para rastrear si los campos han sido tocados
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [touchedNombre, setTouchedNombre] = useState(false);

  const handleClickAgendar = (day) => {
    setSelectedDay(day);
    setSelectedHora(0);
    setLoadingHoras(true);

    getlist_opciones(day).then((data) => {
      if (day.getDay() === 6) {
        data = data.filter((v) => moment(v, "HH:mm").hours() < 13);
      }

      setLoadingHoras(false);
      setOpcionesHoras(data);
    });
  };

  const handleSelectChange = (e) => {
    setSelectedHora(e.target.value);
  };

  function seleccionarFecha() {
    if (selectedHora !== 0 && selectedDay) {
      const horarioDisponible = opcionesHoras.includes(selectedHora);
      if (horarioDisponible) {
        setConfirmarDisabled(false);
        setKeyTab("confirmar");
      } else {
        alert(
          "El horario seleccionado ya está reservado. Por favor, seleccione otro horario."
        );
      }
    } else {
      alert("Por favor, seleccione un horario.");
    }
  }

  const confirmarReserva = async () => {
    try {
      const hora = moment(selectedHora, "HH:mm");
      const fecha = moment(selectedDay)
        .clone()
        .add(hora.hours(), "hours")
        .add(hora.minutes(), "minutes");
      const data = {
        fecha: fecha.unix(),
        fechaLocal: fecha.local().format("YYYY-MM-DD HH:mm:ss"),
        email: inputEmail,
        nombre: inputNombre,
        tipo: tipoReserva,
      };

      const reserva = await crear_reserva(data);
      if (reserva.id) {
        const response = await crearUrlFlow(reserva.id);

        tsuccess("Redireccionando al sitio de pago...");
        window.location.href = `${response.url}?token=${response.token}`;
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      tfail(
        "Error al crear la orden de pago. Por favor, inténtelo nuevamente más tarde."
      );
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Marcar todos los campos como tocados
    setTouchedEmail(true);
    setTouchedNombre(true);

    // Validación
    if (inputEmail === "") {
      tfail("Por favor, ingrese su correo electrónico.");
      setIsLoading(false);
      return;
    } else if (!validateEmail(inputEmail)) {
      tfail("Por favor, ingrese un correo electrónico válido.");
      setIsLoading(false);
      return;
    }

    if (inputNombre.trim() === "") {
      tfail("El nombre es obligatorio.");
      setIsLoading(false);
      return;
    }

    confirmarReserva();
  };

  return (
    <>
      <Button
        variant="rounded animate__animated animate__pulse animate__infinite"
        className="custom-button-lila text-white"
        onClick={handleShow}
      >
        Agendar ahora
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selecciona el día y la hora de tu sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Tabs
              id="controlled-tab-example"
              activeKey={keyTab}
              onSelect={(k) => setKeyTab(k)}
              className="mb-3"
              fill
            >
              <Tab eventKey="home" title="Seleccionar día">
                <div className="row d-flex">
                  <div className="col-12 d-flex justify-content-center">
                    <DayPicker
                      locale={es}
                      onDayClick={handleClickAgendar}
                      disabled={disabledDays}
                      hidden={hiddenDays}
                      mode="single"
                      selected={selectedDay}
                    />
                  </div>
                  <div className="col-12 p-3">
                    <Form.Group className="d-flex">
                      {isLoadingHoras ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className="mx-auto"
                        />
                      ) : (
                        <Form.Select
                          value={selectedHora}
                          onChange={handleSelectChange}
                        >
                          <option value={0}>
                            {opcionesHoras.length > 0
                              ? "Seleccione una opción"
                              : "No existen horas disponibles"}
                          </option>
                          {opcionesHoras.map((v, k) => (
                            <option key={k} value={v}>
                              {v}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 d-flex justify-content-end p-3">
                    <Button variant="primary" onClick={seleccionarFecha}>
                      Siguiente
                    </Button>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="confirmar"
                disabled={confirmarDisabled}
                title="Confirmar hora"
              >
                <div className="col-12">
                  <p>Fecha: {moment(selectedDay).format("DD/MM/YYYY")}</p>
                  <p>Horario: {selectedHora}</p>
                </div>
                <div className="col-12 d-flex justify-content-start pt-5">
                  <Form
                    onSubmit={handleSubmit}
                    className="w-100 d-flex flex-column justify-content-center align-items-center p-4"
                  >
                    <Form.Group className="mb-3 w-75">
                      <Form.Label>Correo electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        value={inputEmail}
                        placeholder="Ingresa tu correo"
                        onChange={(e) => setInputEmail(e.target.value)}
                        onBlur={() => setTouchedEmail(true)}
                        required
                        isInvalid={touchedEmail && !validateEmail(inputEmail)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa un correo electrónico válido.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4 w-75">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={inputNombre}
                        placeholder="Tu nombre"
                        onChange={(e) => setInputNombre(e.target.value)}
                        onBlur={() => setTouchedNombre(true)}
                        required
                        isInvalid={touchedNombre && inputNombre.trim() === ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        El nombre es obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      variant="success"
                      disabled={isLoading}
                      type="submit"
                      className="w-75 mb-4"
                    >
                      {isLoading ? (
                        <>
                          <Spinner animation="border" size="sm" /> Cargando
                        </>
                      ) : (
                        <>
                          <span>Confirmar datos {" "}</span>
                          <span>y proceder al pago</span>
                        </>
                      )}
                    </Button>

                    <ul
                      className="w-75 text-muted"
                      style={{
                        fontSize: "0.9rem",
                        textAlign: "left",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <li className="mb-2">
                        Cambiar el horario de tu cita es posible con al menos 24
                        horas de anticipación.
                      </li>
                      <li className="mb-2">
                        Ten en cuenta que el pago realizado no es reembolsable.
                      </li>
                      <li>Solo se permite reagendar una vez.</li>
                    </ul>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalAgendar;
