// services/agendaService.js
import { getJson, postJson } from "../utils";

export async function crear_reserva(data) {
  const response = await postJson("/ficha/create", data);
  return response.json();
}

export async function crearUrlFlow(reservaId) {
  const response = await postJson("/flow/create_url", { id: reservaId });
  return response.json();
}


export async function getListServicios(alternative_id) {
  const response = await getJson("/agenda/getlist_servicios", { alternative_id });
  return response.json();
}
