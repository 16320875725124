import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Image, NavDropdown, Button } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import ModalLogin from "components/ModalLogin";
import ModalSignup from "components/ModalSignup";

import logo from "../images/logo04092024_150.png";
import { AuthContext } from "api/AuthContextProvider";
import { tsuccess } from "tools";

const UserDropdown = () => {
  const { logout, current_user } = useContext(AuthContext);
  return (
    <NavDropdown
      align="end"
      title={
        <div
          className="avatar"
          style={{
            backgroundColor: `#ffb3c3`, //
          }}
        >
          <span className="initial">{current_user.nombre[0]}</span>
        </div>
      }
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item onClick={() => tsuccess("Aún en construcción jeje.")}>
        Mi perfil
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={logout}>Cerrar sesión</NavDropdown.Item>
    </NavDropdown>
  );
};

function CustomNavbar(props) {
  const { isAuthenticated, current_user } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Efecto para manejar el scroll cuando el hash cambie en la URL
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleServiciosClick = () => {
    if (location.pathname !== "/") {
      // Si no estamos en la página de inicio, redirigir al inicio y luego hacer scroll
      navigate("/#section_agenda");
    } else {
      // Si ya estamos en la página de inicio, simplemente hacer scroll
      const section = document.getElementById("section_agenda");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div>
      <Navbar bg="white" expand="lg" className="shadow-sm rounded">
        <Navbar.Brand as={NavLink} to="/" className="px-5 ml-5">
          <Image
            src={logo}
            alt="Logo"
            height="60"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link as={NavLink} to="/">
              Inicio
            </Nav.Link>
            <Nav.Link onClick={handleServiciosClick}>Servicios</Nav.Link>
            <Nav.Link as={NavLink} to="/sobremi">
              Sobre mí
            </Nav.Link>
            <Nav.Link as={NavLink} to="/preguntas">
              Preguntas frecuentes
            </Nav.Link>
            <Nav.Link as={NavLink} to="/regalos">
              Regalos 🎁
            </Nav.Link>
          </Nav>
          <Nav>
            {!isAuthenticated ? (
              <div>
                <Button
                  variant="primary"
                  className="custom-button-lila"
                  onClick={() => setShowLogin(true)}
                >
                  Iniciar sesión
                </Button>
                <ModalLogin
                  show={showLogin}
                  handleClose={() => setShowLogin(false)}
                  handleSignup={() => setShowSignup(true)}
                ></ModalLogin>

                <Button variant="link" onClick={() => setShowSignup(true)}>
                  Crea tu cuenta
                </Button>
                <ModalSignup
                  show={showSignup}
                  handleClose={() => setShowSignup(false)}
                ></ModalSignup>
              </div>
            ) : (
              <div>
                <UserDropdown></UserDropdown>
                {current_user.nivel >= 999 ? (
                  <Nav.Link as={NavLink} to="/admin">
                    Revisar horas
                  </Nav.Link>
                ) : (
                  ""
                )}
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
