import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { tsuccess, tfail } from "tools";

export const AuthContext = createContext();

export const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.tunutriamiga.cl/"
    : "http://localhost:5000/";

export function getToken() {
  const token = localStorage.getItem("token");
  //const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTEiLCJleHAiOjE2ODQxNTYzMTJ9.gnTx9Enq8ElyXxmkM-JuxhmnK4WIbfEFbVaAda-JeNw";
  if (token) {
    return token;
  } else {
    return "";
  }
}

const AuthContextProvider = ({ children }) => {
  // Estado de autenticación
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [current_user, setCurrentUser] = useState({});

  // Configurar Axios con las credenciales de autenticación headers: {"Content-Type": "application/json", // x-www-form-urlencoded
  const api = axios.create({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    baseURL: apiUrl,
  });

  // Comprobar si el usuario actual está autenticado
  useEffect(() => {
    const api = axios.create({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "x-access-token": `${getToken()}`,
      },
      baseURL: apiUrl,
    });

    console.log("useeffect");
    console.log(getToken());

    api
      .get("validarToken")
      .then((response) => {
        if (response.data.is_authenticated) {
          tsuccess("Hola otra vez :)");
          setCurrentUser(response.data.user);
          return setIsAuthenticated(response.data.is_authenticated);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  // Proporcionar una función para iniciar sesión
  const login = (email, password) => {
    let data = new URLSearchParams();
    data.append("email", email);
    data.append("password", password);

    return api
      .post("login", data.toString())
      .then((response) => {
        if (response.data) {
          tsuccess("Sesión iniciada");
          setIsAuthenticated(true);
          setCurrentUser(response.data.user);
          localStorage.setItem("token", response.data.token);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          tfail(error.response.data.message);
        } else {
          console.error(error);
        }
        throw error;
      });
  };

  // Proporcionar un token para iniciar sesión con Google
  const googleValidateToken = (token) => {
    let data = new URLSearchParams();
    data.append("token", token);
    return api
      .post("googleValidateToken", data.toString())
      .then((response) => {
        if (response.data) {
          tsuccess("Sesión iniciada");
          setIsAuthenticated(true);
          setCurrentUser(response.data.user);
          localStorage.setItem("token", response.data.token);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          tfail(error.response.data.message);
        } else {
          console.error(error);
        }
        throw error;
      });
  };

  const signup = (payload) => {
    return api
      .post("/signup", payload)
      .then((response) => {
        if (response.data) {
          tsuccess("Bienvenida/o a Nutri Amiga!");
          setIsAuthenticated(true);
          setCurrentUser(response.data.user);
          localStorage.setItem("token", response.data.token);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          tfail(error.response.data.message);
        } else {
          console.error(error);
        }
        throw error;
      });
  };

  // Proporcionar una función para cerrar sesión
  const logout = () => {
    return new Promise((resolve) => {
      console.log("logout");
      setCurrentUser({});
      localStorage.removeItem("token");
      setIsAuthenticated(false);

      console.log(getToken());
      resolve();
    }).catch((error) => console.log(error));
  };

  // Proporcionar el estado de autenticación y las funciones de inicio/cierre de sesión a los componentes secundarios
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        current_user,
        setCurrentUser,
        setIsAuthenticated,
        login,
        logout,
        signup,
        googleValidateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
