import { api } from "api";
import { AuthContext } from "api/AuthContextProvider";
import moment from "moment";
import { useContext, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { handleError, tsuccess } from "tools";
import { crearUrlFlow, crear_reserva } from "services/agendaService";

function ModalFicha({ title, precio }) {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputNombre, setInputNombre] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    confirmarReserva();
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [isLoading, setIsLoading] = useState(false);

  const confirmarReserva = async () => {
    try {
      const data = {
        fecha: moment().unix(),
        fechaLocal: moment().local().format("YYYY-MM-DD HH:mm:ss"),
        email: inputEmail,
        nombre: inputNombre,
        tipo: "paciente_ficha"
      };

      // obtener id de reserva
      const reserva = await crear_reserva(data);
      if (reserva.id){
        // obtener url de pago
        const response = await crearUrlFlow(reserva.id);

        tsuccess("Redireccionando al sitio de pago...");
        window.location.href = `${response.url}?token=${response.token}`;
        setIsLoading(false);
      }
      
    } catch (error) {
      console.log(error);
      handleError(
        "Error al crear la orden de pago. Por favor, inténtelo nuevamente más tarde."
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="success rounded animate__animated animate__pulse animate__infinite"
        onClick={handleShow}
      >
        Solicitar ahora 💜
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Asesoría nutricional con ficha en línea</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <h6 className="fw-bold mb-4">{title}</h6>
              <p>
                Para comenzar tu plan de alimentación personalizado, te enviaré
                un enlace a un formulario que deberás completar. Junto con el
                formulario, recibirás un manual de instrucciones para la toma de
                medidas corporales. En el formulario encontrarás un espacio
                libre para que puedas explayarte y escribirme todo lo que desees
                sobre tus objetivos y necesidades. Una vez que hayas completado
                el formulario, recibirás tu pauta de alimentación personalizada
                por correo electrónico en un plazo máximo de una semana. Si
                tienes alguna duda o consulta, puedes contactarme por correo
                electrónico en cualquier momento. Mi objetivo es ayudarte a
                lograr una alimentación saludable y sostenible a largo plazo.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            <Form
              onSubmit={handleSubmit}
              className="w-100 d-flex flex-column justify-content-center align-items-center"
            >
              <Form.Group className="mb-3">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  value={inputEmail}
                  placeholder="Ingresa tu correo"
                  onChange={(e) => setInputEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  value={inputNombre}
                  placeholder="Tu nombre"
                  onChange={(e) => setInputNombre(e.target.value)}
                />
              </Form.Group>

              <Button variant="success" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" />
                     Cargando
                  </>
                ) : (
                  "Confirmar"
                )}
              </Button>
            </Form>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalFicha;
