import camiPerfil1 from "images/cami_perfil.jpg";
import camiPerfil2 from "images/cami/cami1.jpg";
import camiPerfil3 from "images/cami/cami2.jpg";
import camiPerfil4 from "images/cami/cami3.jpg";
import camiPerfil5 from "images/cami/cami4.jpg";
import imagenFideos from "images/comida/fideos.jpg";
import imagenTorta from "images/comida/torta.jpg";

import { Button, Image, Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./SectionSobreMi.css"; // Asegúrate de tener este archivo para los estilos personalizados

function SectionSobreMi() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleServiciosClick = () => {
    if (location.pathname !== "/") {
      navigate("/#section_agenda");
    } else {
      const section = document.getElementById("section_agenda");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <Container fluid className="section-sobre-mi py-5">
      {/* Sección 1: Introducción */}
      <Row className="align-items-center mb-5">
        {/* Imagen a la izquierda */}
        <Col lg={4} md={6} className="text-center mb-4 mb-lg-0">
          <Image
            src={camiPerfil4}
            className="perfil-image-custom mx-auto d-block shadow-lg"
            alt="Perfil de Camila Maturana, nutricionista integral"
          />
        </Col>
        {/* Texto a la derecha */}
        <Col lg={6} md={6}>
          <h2 className="display-6 fw-bold mb-4 text-pink-pastel">
            ¡Hola! Soy Camila
          </h2>

          <p className="text-muted lead">
            tu nutri amiga y una apasionada de la nutrición. Llevo varios años
            acompañando a personas como tú a mejorar su relación con la comida y
            a dejar atrás las dietas que sólo generan frustración y culpa. Mi
            objetivo es ayudarte a sentirte bien con lo que comes y, sobre todo,
            contigo misma.
          </p>
          <p className="text-muted lead">
            <strong>
              Juntas vamos a recorrer un camino hacia una vida más saludable,
              tanto física como mentalmente.
            </strong>
          </p>
        </Col>
      </Row>

      {/* Sección 2: Cómo veo la nutrición */}
      <Row className="align-items-center mb-5 flex-md-row-reverse">
        {/* Imagen a la derecha */}
        <Col lg={4} md={6} className="text-center mb-4 mb-lg-0">
          <Image
            src={imagenTorta}
            className="perfil-image-custom mx-auto d-block shadow-lg"
            alt="Camila explicando su filosofía nutricional"
          />
        </Col>
        {/* Texto a la izquierda */}
        <Col lg={6} md={6}>
          <h3 className="fw-bold text-pink-pastel mb-3">
            Cómo veo la nutrición
          </h3>
          <p className="text-muted">
            Para mí, la clave está en la{" "}
            <strong>alimentación consciente</strong> y en respetar las
            necesidades de tu cuerpo. No creo en prohibiciones ni reglas
            rígidas; más bien, te invito a descubrir lo que tu cuerpo realmente
            necesita para que disfrutes de la comida sin culpas. En nuestras
            sesiones, encontrarás un espacio cálido y sin juicios, donde juntas
            aprenderemos a:
          </p>
          <ul className="text-muted">
            <li>Escuchar las señales internas que te entrega tu cuerpo.</li>
            <li>Disfrutar de cada comida, sin sentirte mal.</li>
            <li>Disfrutar cada comida sin culpa ni ansiedad.</li>
            <li>
              Crear hábitos que te hagan sentir bien y que puedas mantener con
              el tiempo.
            </li>
          </ul>
          <p className="text-muted">
            <strong>
              Vamos a cambiar la forma en que te relacionas con la comida y con
              tu cuerpo para que vivas en armonía con tu bienestar.
            </strong>
          </p>
        </Col>
      </Row>

      {/* Sección 3: Un poco más sobre mí */}
      <Row className="align-items-center mb-5">
        {/* Imagen a la izquierda */}
        <Col lg={4} md={6} className="text-center mb-4 mb-lg-0">
          <Image
            src={camiPerfil5}
            className="perfil-image-custom mx-auto d-block shadow-lg"
            alt="Perfil de Camila Maturana disfrutando de sus intereses personales"
          />
        </Col>
        {/* Texto a la derecha */}
        <Col lg={6} md={6}>
          <h3 className="fw-bold text-pink-pastel mb-3">
            Un poco más sobre mí
          </h3>
          <p className="text-muted">
            Aparte de mi trabajo como nutricionista, disfruto muchísimo
            viajando, leyendo, escuchando música y pasando tiempo con mis gatos.
            Además, disfruto mucho junto al coloring, es mi manera de
            desconectar y recargar energías. es mi momento de desconexión y me
            ayuda a recargar energías.
          </p>
          <p className="text-muted">
            Creo que{" "}
            <strong>
              vivir en equilibrio entre disfrutar la vida y cuidarnos
            </strong>{" "}
            es fundamental para sentirnos bien.
          </p>
        </Col>
      </Row>

      {/* Sección 4: Invitación al Cambio */}
      <Row className="align-items-center mb-5 flex-md-row-reverse">
        {/* Imagen a la derecha */}
        <Col lg={4} md={6} className="text-center mb-4 mb-lg-0">
          <Image
            src={camiPerfil3}
            className="perfil-image-custom mx-auto d-block shadow-lg"
            alt="Camila invita a un cambio saludable"
          />
        </Col>
        {/* Texto a la izquierda */}
        <Col lg={6} md={6}>
          <h3 className="fw-bold text-primary-light mb-3">
            ¿Lista para comenzar este viaje?
          </h3>
          <p className="text-muted">
            Da el primer paso hacia una relación más sana con la comida y
            contigo misma.{" "}
            <strong>
              Estoy aquí para acompañarte en cada etapa de este hermoso proceso
              de transformación.
            </strong>
          </p>
          {/* Botón de llamada a la acción */}
          <Button
            variant="primary"
            size="lg"
            className="mt-4 custom-button"
            onClick={handleServiciosClick}
            aria-label="Trabajemos juntas"
          >
            ¡Trabajemos Juntas!
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default SectionSobreMi;
