import React from "react";

function PrivacidadPage(props) {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Política de Privacidad de "tuNutriAmiga"</h1>

      <h2>Información que recopilamos</h2>
      <p>
        Cuando creas una cuenta en "tuNutriAmiga", te pedimos que proporciones
        cierta información básica, como tu correo electrónico y país de
        residencia. No compartimos esta información con ninguna otra empresa ni
        la utilizamos con fines publicitarios.
      </p>

      <p>
        Además, al utilizar nuestra aplicación, recopilamos información sobre
        tus citas y el uso que haces de nuestra aplicación para poder mejorar la
        experiencia del usuario. Esta información no está asociada a tu nombre
        ni a tu dirección de correo electrónico.
      </p>

      <h2>Uso de la información</h2>
      <p>
        Utilizamos la información que recopilamos para proporcionarte nuestros
        servicios de nutrición online y mejorar continuamente la experiencia de
        usuario.
      </p>
      <p>
        No compartimos tu información personal con terceros, ni la vendemos ni
        la alquilamos.
      </p>

      <h2>Seguridad</h2>
      <p>
        Nos comprometemos a proteger la seguridad de tu información personal.
        Tomamos medidas razonables para proteger la información que recopilamos
        contra el acceso no autorizado o el uso ilícito.
      </p>

      <h2>Cambios a la Política de Privacidad</h2>
      <p>
        Nos reservamos el derecho de actualizar o modificar esta Política de
        Privacidad en cualquier momento y sin previo aviso. Si realizamos
        cambios significativos en nuestra Política de Privacidad, te lo
        informaremos a través de la aplicación o por correo electrónico.
      </p>

      <h2>Contacto</h2>
      <p>
        Si tienes preguntas sobre esta Política de Privacidad, por favor
        contáctanos en info@tunutriamiga.com.
      </p>

      <p>
        Gracias por confiar en "tuNutriAmiga" como tu servicio de nutrición
        online.
      </p>
    </div>
  );
}

export default PrivacidadPage;
