import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

export const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export const tsuccess = (mensaje) =>
  toast.success(mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    className: "notification-custom",
  });

export const tfail = (mensaje) =>
  toast.error(mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    className: "notification-custom",
  });

export const handleError = (
  mensaje = "Ha ocurrido un error. Por favor, inténtelo nuevamente más tarde."
) =>
  toast.error(mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    className: "notification-custom",
  });
