import React from "react";
import { Button, Carousel } from "react-bootstrap";

// Importar las imágenes directamente
import image1 from "../../../images/carousel/carousel1_960.png";
import image2 from "../../../images/carousel/carousel2_960.png";
import image3 from "../../../images/carousel/carousel3_960.png";
import image4 from "../../../images/carousel/carousel4_960.png";

function Hero() {
  const handleClick = () => {
    const section = document.getElementById("section_enfoque");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const images = [image1, image2, image3, image4];

  return (
    <section style={heroStyles}>
      {/* Carousel como fondo */}
      <Carousel
        controls={false}
        indicators={false}
        fade
        interval={3000}
        pause={false}
        className="hero-carousel" // Agregamos una clase para estilos específicos
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {images.map((img, index) => (
          <Carousel.Item key={index}>
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              className="d-block w-100"
              style={{
                height: "100vh", // Forzar la altura completa
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Superposición oscura */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />

      {/* Contenido del Hero */}
      <div
        className="container h-100 d-flex align-items-center"
        style={{ zIndex: 2, position: "relative" }}
      >
        <div className="row w-100 justify-content-center text-center">
          <div className="col-lg-6 col-sm-8">
            <h1
              className="display-4 fw-bold mb-3"
              style={{
                color: "#fff",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
              }}
            >
              Reconecta con tu cuerpo y transforma tu relación con la comida.
            </h1>
            <p
              className="lead fw-bold mb-4"
              style={{
                color: "#fff",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
              }}
            >
              Descubre una nueva forma de nutrir tu cuerpo, sin dietas
              restrictivas ni culpas y construye un bienestar que va más allá
              del peso.
            </p>
            <Button
              size="lg"
              onClick={handleClick}
              className="custom-button-lila"
              aria-label="Agenda tu hora"
            >
              Comienza tu viaje hoy
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

// Estilos de la sección del hero
const heroStyles = {
  position: "relative",
  height: "100vh",
  overflow: "hidden",
};

// Estilos adicionales para forzar la expansión del carrusel
const styles = `
  .carousel-inner {
    height: 100vh !important;  /* Forzar la altura completa */
  }

  .carousel-item img {
    height: 100vh !important;  /* Asegurarse que las imágenes también cubran toda la pantalla */
    object-fit: cover;
    object-position: center;
  }
`;

// Inyectar los estilos en la página
document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);

export default Hero;
