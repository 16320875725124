import React from "react";
import { Fade } from "react-awesome-reveal";
import { FaStar } from "react-icons/fa";

import image1 from "../../../images/testimonios/testimonio1.png"; // Cambiar el nombre si es necesario
import image2 from "../../../images/testimonios/testimonio2.png";
import image3 from "../../../images/testimonios/testimonio3.png";
import image4 from "../../../images/testimonios/testimonio4.png";

function Testimonios() {
  const testimonios = [
    {
      author: "Alejandra",
      text: "La nutricionista es genial además de escuchar cada uno de tus requerimientos siempre está dispuesta a responder dudas y llevar un seguimiento, las consultas son un proceso empatico, respetuoso y divertida.Los planes de alimentación personalizados y me han ayudado a mejorar mi salud y mi  bienestar en general.",
      stars: 5,
      image: image1, // Cambia por tus imágenes
    },
    {
      author: "Romina",
      text: "Lo que me gusta es que cami no juzga, entiende que es un proceso largo y muchas veces dificil. No me siento presionada a seguir objetivos rígidos, entiende que tenemos dias y dias. Y sobretodo me gusta que se centra en que tenemos que querernos como somos y no sentirnos culpables por comer. Me motiva a seguir dando pequeños pasos para mejorar mi relación con los alimentos.",
      stars: 5,
      image: image2,
    },
    {
      author: "Polet",
      text: "Me sentí en total confianza en toda la sesión, me sentí bastante cómoda ya que fuiste capaz de crear el ambiente para hablar de mis problemas que dificultan mi buena alimentación y de como ir paso a pasito para mejorar <3",
      stars: 5,
      image: image3,
    },
    {
      author: "Esperanza",
      text: "Mi experiencia con la Cami ha sido demasiado bacan, en muchas ocasiones visitaba médicos, no necesariamente nutricionistas, que lo único que me decían era lo que no tenía que comer y hacer más ejercicio (algo difícil para mi) ya que bajando de peso iba a 'mejorar'. La Cami mira más allá de lo físico de la persona, se introduce en la vida y las emociones de los usuarios, y cómo esto podría influir en su alimentación, eso me gusto mucho. Además, constantemente esta conversando con uno, resolviendo preguntas, acompañando el proceso o simplemente para saber cómo va tu vida. Las atenciones son muy personalizadas y sin prejuicios 💖",
      stars: 5,
      image: image4,
    },
  ];

  return (
    <section
      id="section_testimonios"
      className="section py-5"
      style={{ backgroundColor: "#f9f9f9" }}
    >
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2 className="display-5 fw-bold" style={titleStyle}>
              Lo que nuestros pacientes dicen de su experiencia
            </h2>
          </div>

          {/* Testimonios con animación de Fade */}
          {testimonios.map((testimonial, key) => (
            <div className="col-12 mb-4" key={key}>
              <Fade direction="up" triggerOnce>
                <div
                  className="d-flex align-items-start"
                  style={testimonialStyle}
                >
                  {/* Imagen asociada al testimonio */}
                  <div style={{ marginRight: "20px", minWidth: "100px" }}>
                    <img
                      src={testimonial.image}
                      alt="Testimonio"
                      className="img-fluid rounded-circle"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Contenido del testimonio */}
                  <div>
                    <div className="d-flex align-items-center mb-2">
                      {[...Array(testimonial.stars)].map((_, i) => (
                        <FaStar key={i} color="#f0ad4e" />
                      ))}
                    </div>
                    <p style={textStyle}>"{testimonial.text}"</p>
                    <p style={authorStyle}>- {testimonial.author}</p>
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

// Estilos personalizados
const titleStyle = {
  color: "#333",
  fontSize: "2.5rem",
  marginBottom: "20px",
};

const testimonialStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
};

const textStyle = {
  color: "#555",
  fontSize: "1rem",
  lineHeight: "1.5",
};

const authorStyle = {
  color: "#343a40",
  fontWeight: "bold",
  marginTop: "10px",
};

export default Testimonios;
