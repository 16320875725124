import { Toast } from "tools";
import Contacto from "./components/Contacto";
import Hero from "./components/Hero";
import SectionAgenda from "./components/SectionAgenda";
import SectionEnfoque from "./components/SectionEnfoque";
import Testimonios from "./components/Testimonios";

function HomePage() {
  return (
    <div>
      <Toast></Toast>
      <Hero></Hero>
      <SectionEnfoque></SectionEnfoque>
      <Testimonios></Testimonios>
      <SectionAgenda></SectionAgenda>
      <Contacto></Contacto>
    </div>
  );
}

export default HomePage;
