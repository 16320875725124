import React, { useState, useEffect } from "react";
import { api } from "api";
import { Table, Button, Spinner, Modal, Form } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";

import { FaEdit, FaTrashAlt, FaArrowsAlt } from "react-icons/fa";
import { DayPicker } from "react-day-picker";
import { getlist_opciones } from "api";
import { CalendarioReservas } from "components";
// import "react-day-picker/lib/style.css";

function AdminPage(props) {
  const [reservas, setReservas] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    //getlistReservas(); //TODO: optimizar
  }, []);

  function getlistReservas() {
    api
      .get("getListReservas")
      .then(function (response) {
        setReservas(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleEdit(id) {
    setCurrentId(id);
    setShow(true);
  }

  function handleDelete(id) {
    setLoadingStates({ ...loadingStates, [id]: true }); // para que solo aparezca loading en botones del row
    api
      .post("/deleteReserva", { id: id })
      .then((response) => {
        getlistReservas().then(() => {
          setLoadingStates({ ...loadingStates, [id]: false });
        });
      })
      .catch((e) => {});
  }

  // modal editar
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState("");
  function ModalEditarAgenda({ show }) {
    const [selectedFecha, setSelectedFecha] = useState(new Date());
    const [loading, setLoading] = useState(false);

    // get current reserva
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDayClick = (day) => {
      setSelectedFecha(day);
      getlist_opciones(day).then((data) => {
        const opciones = data; // todo: procesar datos
        // Establecer los horarios disponibles en el estado
        setOpcionesHoras(opciones);
      });
    };

    const [selectedHora, setSelectedHora] = useState(0);
    const handleSelectChange = (e) => {
      setSelectedHora(e.target.value);
    };

    const [opcionesHoras, setOpcionesHoras] = useState([]);

    const handleEnviar = () => {
      setLoading(true);

      var hora = moment(selectedHora, "HH:mm");

      // Sumamos la hora al objeto moment original
      let fecha = moment(selectedFecha)
        .clone()
        .add(hora.hours(), "hours")
        .add(hora.minutes(), "minutes");

      let payload = {
        id: currentId,
        fecha: fecha.unix(),
        fechaLocal: fecha.local().format("YYYY-MM-DD HH:mm:ss"),
      };
      let data = new URLSearchParams(Object.entries(payload));

      api
        .post("/editReserva", data.toString())
        .then(() => {
          setShow(false);
          getlistReservas();
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    };

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Selecciona una fecha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DayPicker onDayClick={handleDayClick} />
            <div>Fecha seleccionada: {selectedFecha.toLocaleDateString()}</div>
            <div className="col-12 p-3">
              <Form.Select value={selectedHora} onChange={handleSelectChange}>
                <option value={0}>
                  {opcionesHoras.length > 0
                    ? "Seleccione una opción"
                    : "No existen horas disponibles"}
                </option>
                {opcionesHoras.map((v, k) => (
                  <option key={k} value={v}>
                    {v}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={loading} onClick={handleEnviar}>
              {loading ? <Spinner animation="border" size="sm" /> : "Guardar"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-12 py-4 mb-5">
          <div className="card">
            <div className="card-body">
              <CalendarioReservas initialEvents={reservas} />
            </div>
          </div>
        </div>
        <div className="col-12">
          <ModalEditarAgenda show={show}></ModalEditarAgenda>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
