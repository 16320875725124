import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import moment from "moment";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { api } from "api";
import { handleError, tsuccess } from "tools";
import { set } from "date-fns";
import { es } from "date-fns/locale";

const createEvent = (event) => ({
  title: event.descripcion,
  start: moment(event.fechaLocal).toISOString(),
  end: moment(event.fechaLocalFin).toISOString(),

  extendedProps: {
    email: event.email,
    nombre: event.nombre,
    tipo: event.tipo,
    descripcion: event.descripcion,
    pk: event.id,
  },
});

function CalendarioReservas() {
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    console.log("useEffect calendarios");
  }, []);

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [fecha, setFecha] = useState();
  const [horaInicio, setHoraInicio] = useState();
  const [horaFin, setHoraFin] = useState();
  const [inputDescripcion, setinputDescripcion] = useState("");

  const [isLoading, setLoading] = useState(false);

  // modal nuevo evento
  const handleShowModal = () => setShow(true);
  const handleCloseModal = () => setShow(false);

  // logica para guardar evento
  const handleSaveEvent = () => {
    setLoading(true);

    // determinar fecha con hora de inicio y fin
    let fecha_inicio = moment(fecha).clone().toDate();
    fecha_inicio.setHours(moment(horaInicio, "HH:mm").hour());
    fecha_inicio.setMinutes(moment(horaInicio, "HH:mm").minutes());
    fecha_inicio = moment(fecha_inicio);

    let fecha_fin = moment(fecha).clone().toDate();
    if (horaFin && moment(horaFin, "HH:mm").isValid()) {
      fecha_fin.setHours(moment(horaFin, "HH:mm").hour());
      fecha_fin.setMinutes(moment(horaFin, "HH:mm").minutes());
    } else {
      // Si horaFin es null o NaN, agregamos una hora a la fecha_inicio
      fecha_fin = moment(fecha_inicio).add(1, "hour");
    }
    fecha_fin = moment(fecha_fin);

    console.log(isEditing);
    if (isEditing) {
      // Lógica para editar el evento seleccionado
      api
        .post("/admin/editar_reserva", {
          id: selectedEvent.extendedProps.pk,
          descripcion: inputDescripcion,
          email: email,
          nombre: nombre,
          fecha: fecha_inicio.unix(),
          fecha_fin: fecha_fin.unix(),
          fechaLocal: moment(fecha_inicio)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          fechaLocalFin: moment(fecha_fin)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((response) => {
          const event = response.data;

          // remover evento editado de events
          const eventosFiltrados = events.filter((e) => e.pk !== event.id);

          // agregar evento editado
          setEvents([...eventosFiltrados, createEvent(event)]);

          tsuccess("Reserva editada correctamente");
          setLoading(false);
          handleCloseModal();
        })
        .catch((error) => {
          handleError(
            "Error al crear la reserva. Por favor, inténtelo nuevamente más tarde."
          );
          setLoading(false);
        });
    } else {
      // Lógica para crear un nuevo evento
      api
        .post("/admin/crear_reserva", {
          descripcion: inputDescripcion,
          nombre: nombre,
          email: email,
          fecha: fecha_inicio.unix(),
          fecha_fin: fecha_fin.unix(),
          fechaLocal: moment(fecha_inicio)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          fechaLocalFin: moment(fecha_fin)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((response) => {
          const event = response.data;

          console.log(events);

          tsuccess("Reserva creada correctamente");
          setEvents([...events, createEvent(event)]);
          setLoading(false);
          handleCloseModal();
        })
        .catch((error) => {
          handleError(
            "Error al crear la reserva. Por favor, inténtelo nuevamente más tarde."
          );
          setLoading(false);
        });
    }

    handleCloseModal();
  };

  // click en fecha
  const handleDateClick = (clickInfo) => {
    setIsEditing(false);

    // limpiar campos
    setSelectedEvent(null);
    setinputDescripcion("");
    setNombre("");
    setEmail("");
    setFecha(clickInfo.date);
    setHoraInicio(moment(clickInfo.date).format("HH:mm"));
    setHoraFin(moment(clickInfo.date).add(1, "hour").format("HH:mm"));

    handleShowModal();
  };

  // click en evento
  const handleEventClick = (clickInfo) => {
    console.log(clickInfo.event);
    // llenar campos
    setIsEditing(true);
    setSelectedEvent(clickInfo.event);
    setinputDescripcion(clickInfo.event.title);
    setNombre(clickInfo.event.extendedProps.nombre);
    setEmail(clickInfo.event.extendedProps.email);
    setFecha(clickInfo.event.start);
    setHoraInicio(moment(clickInfo.event.start).format("HH:mm"));
    setHoraFin(moment(clickInfo.event.end).format("HH:mm"));

    handleShowModal();
  };

  const handleDeleteEvent = () => {
    setLoading(true);

    api
      .post("/deleteReserva", { id: selectedEvent.extendedProps.pk })
      .then((response) => {
        // remover evento editado de events
        const eventosFiltrados = events.filter(
          (e) => e.extendedProps.pk !== selectedEvent.extendedProps.pk
        );

        // agregar evento editado
        setEvents([...eventosFiltrados]);

        tsuccess("Reserva eliminada correctamente");
        setLoading(false);
        handleCloseModal();
      })
      .catch((e) => {});
  };

  const handleDateSet = ({ startStr, endStr }) => {
    const startUnix = moment(startStr).unix();
    const endUnix = moment(endStr).unix();
    return api
      .get("getlist_reservas", {
        params: { start: startUnix, end: endUnix },
      })
      .then((response) => response.data)
      .then((data) => {
        setEvents(data.map(createEvent));
      });
  };

  const renderEventContent = (eventInfo) => {
    const { descripcion, nombre, tipo } = eventInfo.event.extendedProps;
    const customStyle = {}; //{ backgroundColor: "rgb(202 81 132)" };
    let eventType = "";

    // pasar tipo de evento a nombre de servicio
    // )  # 0: paciente_nuevo, 1: paciente_antiguo, 2: paciente_ficha, 3:paciente_pack
    switch (tipo) {
      case 0:
        eventType = "Paciente nuevo";
        break;
      case 1:
        eventType = "Paciente antiguo";
        break;
      case 2:
        eventType = "Paciente formulario";
        break;
      case 3:
        eventType = "Paciente pack";
        break;
      default:
        break;
    }
    return (
      <>
        <div className="event-content" style={customStyle}>
          <span className="event-title d-block">
            {eventInfo.timeText + "  "}
            {nombre} {descripcion}
            <p>{eventType}</p>
          </span>
        </div>
      </>
    );
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handleEventResize = (eventResizeInfo) => {
    const event = eventResizeInfo.event;
    const fecha_inicio = moment(event.start).clone();
    const fecha_fin = moment(event.end).clone();

    console.log("handleEventResize", event, fecha_inicio, fecha_fin);

    api
      .post("/admin/editar_reserva", {
        id: event.extendedProps.pk,
        descripcion: event.title,
        email: event.extendedProps.email,
        fecha: fecha_inicio.unix(),
        fecha_fin: fecha_fin.unix(),
        fechaLocal: fecha_inicio.format("YYYY-MM-DD HH:mm:ss"),
        fechaLocalFin: fecha_fin.format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((response) => {
        const event = response.data;

        // remover evento editado de events
        const eventosFiltrados = events.filter(
          (e) => e.extendedProps.pk !== event.id
        );

        // agregar evento editado
        setEvents([...eventosFiltrados, createEvent(event)]);

        tsuccess("Reserva editada correctamente");
        setLoading(false);
        handleCloseModal();
      })
      .catch((error) => {
        handleError(
          "Error al crear la reserva. Por favor, inténtelo nuevamente más tarde."
        );
        setLoading(false);
      });
  };

  const initialView = isMobile() ? "threeDayView" : "timeGridWeek";
  const avaibleViews = isMobile()
    ? "threeDayView, timeGridDay"
    : "dayGridMonth, timeGridWeek, timeGridDay, threeDayView";

  return (
    <div className="">
      <h1 className="mb-5">Calendario</h1>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: avaibleViews,
        }}
        initialView={initialView}
        selectable={true}
        events={events}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        locale={esLocale}
        datesSet={handleDateSet}
        eventContent={renderEventContent}
        slotMinTime={"08:00:00"}
        slotMaxTime={"22:00:00"}
        editable={true}
        eventResize={handleEventResize}
        eventDrop={handleEventResize}
        height={"auto"}
        views={{
          threeDayView: {
            type: "timeGrid",
            duration: { days: 3 },
            buttonText: "3 días", // Texto del botón para seleccionar la vista
          },
        }}
      />
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Editar evento" : "Agregar evento"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputDescripcion">Descripción</Form.Label>
            <Form.Control
              type="text"
              id="inputDescripcion"
              value={inputDescripcion}
              onChange={(e) => setinputDescripcion(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputNombre">Nombre</Form.Label>
            <Form.Control
              type="text"
              id="inputNombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputEmail">Email</Form.Label>
            <Form.Control
              type="email"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputFecha">Fecha</Form.Label>
            <Form.Control
              type="date"
              id="inputFecha"
              value={moment(fecha).format("YYYY-MM-DD")}
              onChange={(e) => setFecha(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputHora">Inicio</Form.Label>
            <Form.Control
              type="time"
              id="inputHora"
              value={horaInicio}
              onChange={(e) => setHoraInicio(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="inputHoraFin">Fin</Form.Label>
            <Form.Control
              type="time"
              id="inputHoraFin"
              value={horaFin}
              onChange={(e) => setHoraFin(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="success"
            type="button"
            onClick={handleSaveEvent}
            disabled={isLoading}
            className="me-3"
          >
            {isLoading ? (
              <>
                <Spinner animation="border" size="sm" />
                Cargando
              </>
            ) : isEditing ? (
              "Guardar"
            ) : (
              "Confirmar"
            )}
          </Button>
          {isEditing && (
            <Button
              variant="outline-danger"
              onClick={handleDeleteEvent}
              disabled={isLoading}
            >
              {"Eliminar"}
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CalendarioReservas;
