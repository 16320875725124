import React from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

function Contacto() {
  return (
    <footer className="shadow-sm" id="contact" style={footerStyle}>
      <div className="container">
        <div className="row py-4 d-flex justify-content-between align-items-center">
          {/* Sección de contacto */}
          <div className="col-md-6">
            <h5 style={titleStyle}>Contáctanos</h5>
            <p className="text-muted" style={textStyle}>
              Si tienes alguna pregunta, no dudes en contactarnos.
            </p>
            <a
              href="mailto:camila.tunutriamiga@gmail.com"
              className="d-flex align-items-center"
              style={linkStyle}
            >
              <SiGmail size={24} style={iconStyle} />{" "}
              <span style={emailTextStyle}>camila.tunutriamiga@gmail.com</span>
            </a>
          </div>

          {/* Sección de redes sociales */}
          <div className="col-md-6 d-flex justify-content-end">
            <h5 style={titleStyle}>Síguenos</h5>
            <div className="d-flex align-items-center">
              <a
                href="https://instagram.com/tunutriamiga_?igshid=YmMyMTA2M2Y="
                className="btn btn-outline-light btn-social rounded-circle mx-2"
                style={socialBtnStyle}
                aria-label="Instagram"
              >
                <FaInstagram size={20} style={iconStyle} />
              </a>

              <a
                href="https://web.whatsapp.com/send/?phone=56990504908&text=Hola%21+Me+gustar%C3%ADa+m%C3%A1s+informaci%C3%B3n+sobre+las+consultas+nutricionales"
                className="btn btn-outline-light btn-social rounded-circle mx-2"
                style={socialBtnStyle}
                aria-label="WhatsApp"
              >
                <FaWhatsapp size={20} style={iconStyle} />
              </a>

              <a
                href="mailto:camila.tunutriamiga@gmail.com"
                className="btn btn-outline-light btn-social rounded-circle mx-2"
                style={socialBtnStyle}
                aria-label="Correo Electrónico"
              >
                <SiGmail size={20} style={iconStyle} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

// Estilos personalizados
const footerStyle = {
  backgroundColor: "#EAF8E6", // Fondo verde suave
  paddingTop: "20px",
  paddingBottom: "20px",
};

const titleStyle = {
  color: "#343a40",
  fontWeight: "bold",
  marginBottom: "15px",
};

const textStyle = {
  fontSize: "1rem",
  color: "#6c757d",
  marginBottom: "10px",
};

const linkStyle = {
  color: "#28a745",
  textDecoration: "none",
  fontSize: "1rem",
};

const emailTextStyle = {
  marginLeft: "10px",
  fontSize: "1rem",
  color: "#28a745",
};

const iconStyle = {
  color: "#fff", // Verde para unificar el estilo
};

const socialBtnStyle = {
  display: "inline-block",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "#28a745", // Verde para unificar el estilo
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background-color 0.3s ease",
  marginLeft: "10px",
  cursor: "pointer",
};

export default Contacto;
