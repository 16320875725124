import React from "react";
import { Fade } from "react-awesome-reveal";
import { Card, Button } from "react-bootstrap";

function SectionEnfoque() {
  const handleClick = () => {
    const section = document.getElementById("section_testimonios"); // Asegúrate que esta sección tenga este id
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const descripcion = [
    {
      title: "Consulta Nutricional Personalizada",
      descripcion:
        "Recibe orientación especializada y ajustada a tus necesidades.",
      icon: "💜", // Puedes reemplazar con íconos personalizados o SVG
    },
    {
      title: "Programa de 3 sesiones",
      descripcion:
        "Sigue un plan de 3 sesiones para establecer hábitos saludables.",
      icon: "✨",
    },
  ];

  return (
    <section id="section_enfoque" className="section-full" style={sectionStyle}>
      <div className="container py-5">
        {/* Título y Descripción */}
        <div className="text-center mb-5">
          <h2 className="display-5 fw-bold" style={titleStyle}>
            Alimentación que transforma, sin dietas restrictivas
          </h2>
          <p style={descriptionStyle}>
            Mejora tu relación con la comida y tu cuerpo, a través de un enfoque
            no pesocentrista que respeta tus necesidades y estilo de vida
          </p>
        </div>

        {/* Tarjetas de Enfoque con animación de Fade */}
        <div className="row d-flex justify-content-center">
          {descripcion.map((item, index) => (
            <div key={index} className="col-md-4 mb-4">
              <Fade direction="up" triggerOnce>
                <Card className="shadow-sm" style={cardStyle}>
                  <Card.Body className="text-center">
                    <div style={{ fontSize: "3rem", color: "#82c91e" }}>
                      {item.icon}
                    </div>
                    <h5 className="mt-3 mb-3 fw-bold">{item.title}</h5>
                    <p>{item.descripcion}</p>
                  </Card.Body>
                </Card>
              </Fade>
            </div>
          ))}
        </div>

        {/* Botón de llamada a la acción */}
        <div className="text-center mt-5">
          <Button
            size="lg"
            variant="success"
            className="px-4 custom-button-lila"
            style={buttonStyle}
            onClick={handleClick}
          >
            Comienza tu transformación
          </Button>
        </div>
      </div>
    </section>
  );
}

// Estilos personalizados
const sectionStyle = {
  backgroundColor: "#f7f8fa", // Fondo suave
  padding: "60px 0",
};

const titleStyle = {
  color: "#343a40", // Color oscuro para título
  fontSize: "2.5rem",
  marginBottom: "20px",
};

const descriptionStyle = {
  color: "#6c757d", // Texto gris para el subtítulo
  maxWidth: "700px", // Limitar el ancho del texto
  margin: "0 auto",
  fontSize: "1.2rem",
};

const cardStyle = {
  borderRadius: "12px", // Bordes redondeados para suavidad
  padding: "20px",
  backgroundColor: "#ffffff",
  transition: "transform 0.3s ease-in-out",
  minHeight: "220px",
};

const buttonStyle = {
  backgroundColor: "#82c91e",
  borderColor: "#82c91e",
  borderRadius: "30px",
};

export default SectionEnfoque;
