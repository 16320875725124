import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

function PageRegalos() {
  const descargable = [
    {
      title: "Listado de compras (plantilla)",
      descripcion: "Organiza tus compras con esta plantilla descargable.",
      url: "https://drive.google.com/file/d/1k7Ixf5VI-uZt_MAbVcovUbvp07RPRAb4/view?usp=drive_link",
    },
    {
      title: "Menú diario (plantilla)",
      descripcion: "Planifica tus comidas diarias de manera sencilla.",
      url: "https://drive.google.com/file/d/1BhcV4LCOKfIIaZh5PKP3LSYSNRnLVGt5/view?usp=drive_link",
    },
    {
      title: "Planner semanal (plantilla)",
      descripcion: "Organiza tu semana con este práctico planner.",
      url: "https://drive.google.com/file/d/103GrCQONI86D3DvIEIrbzolhWwWIWFoR/view?usp=drive_link",
    },
  ];

  return (
    <div className="container-fluid">
      <section
        id="material"
        className="section-mid"
        style={{ backgroundColor: "#FFDB94" }}
      >
        <div className="container py-5">
          <div className="row gy-4">
            <div className="col-12 text-center">
              <h2 className="display-5 fw-bold">Material descargable</h2>
              <p className="lead">
                Descarga plantillas gratuitas para organizar tu día a día.
              </p>
            </div>

            {descargable.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <div className="card shadow h-100">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <div>
                      <h5 className="fw-bold">{item.title}</h5>
                      {item.descripcion && (
                        <p className="text-muted">{item.descripcion}</p>
                      )}
                    </div>

                    <Button
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="primary"
                      className="mt-3"
                      aria-label={`Descargar ${item.title}`}
                    >
                      <FaDownload className="me-2" />
                      Descargar
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageRegalos;
