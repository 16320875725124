import React, { useState, useCallback, useContext } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { AuthContext } from "api/AuthContextProvider";
import { tsuccess } from "tools";

const ModalSignup = (props) => {
  const { isAuthenticated, logout, signup, setIsAuthenticated } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    nombre: "",
    pais: "",
  });
  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    },
    [formData]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    signup(formData)
      .then((response) => {
        setIsLoading(false);
        if (response) props.handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Creemos tu cuenta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Tu nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa tu nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCountry">
                <Form.Label>País</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa tu país"
                  name="pais"
                  value={formData.pais}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <div className="col-12 pt-3 d-flex justify-content-center">
                <Button variant="primary" disabled={isLoading} type="submit">
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Crea tu cuenta 😊"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSignup;
