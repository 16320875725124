import { AuthContext } from "api/AuthContextProvider";
import { ModalAgendar, ModalFicha } from "components";
import { useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Button, Modal } from "react-bootstrap";

import image1 from "../../../images/carousel/carousel1_960.png";

function SectionAgenda() {
  const { isAuthenticated } = useContext(AuthContext);

  const servicios = [
    {
      title: "Sesión individual 💖",
      subtitulo:
        "Consulta nutricional en línea a través de videollamada (Google Meet) enfocada en tu bienestar emocional y objetivos personalizados.",
      descripcion:
        "Esta modalidad de consulta nutricional consiste en una videollamada de 60 minutos donde trabajaremos en sanar tu relación con la comida y mejorar tu bienestar emocional. A través de técnicas de alimentación consciente y amor propio, te ayudaré a alcanzar tus metas de manera personalizada.",
      tiempo: "60 minutos",
      precio: "CLP 25.000",
      precio_por_mes: "CLP 25.000 / sesión",
      activo: true,
      tipo: "paciente_nuevo",
      beneficios: [
        "Evaluación personalizada de tus hábitos alimenticios y emociones al comer.",
        "Herramientas para mejorar tu relación con la comida sin dietas restrictivas.",
        "Ejercicios prácticos para reducir la ansiedad y la culpa al comer.",
        "Plan de acción con objetivos a corto plazo para mejorar tu bienestar físico y emocional.",
        "Materiales descargables complementarios.",
        "Acompañamiento vía WhatsApp durante 1 mes.",
      ],
    },
    {
      title: `Programa "Reconectando con mi cuerpo" (3 meses) 🍏`,
      subtitulo:
        "Todo lo que incluye la sesión individual, más un acompañamiento extendido y herramientas adicionales para una transformación completa.",
      descripcion:
        "Este pack te brinda todo lo que incluye la consulta individual: una evaluación nutricional personalizada, minuta de 1 día, y acompañamiento vía WhatsApp por 1 mes. Pero además, obtendrás dos sesiones adicionales para seguimiento, materiales exclusivos de mindfulness alimentario, acceso a una comunidad online de apoyo, talleres grupales mensuales, y sesiones de preguntas y respuestas en vivo. Ideal para quienes buscan un cambio profundo y progresivo.",
      tiempo: "3 sesiones de 60 minutos",
      precio: "CLP 65.000",
      precio_por_mes: "CLP 21.500 / sesión",
      ahorro: "Ahorro de 10.000 CLP",
      activo: true,
      tipo: "pack_3_sesiones",
      beneficios: [
        "1 sesión individual online por mes (3 en total)",
        "Acceso a una comunidad online de apoyo (grupos en WhatsApp o Telegram)",
        "1 taller grupal mensual sobre imagen corporal y manejo de la ansiedad relacionada con la comida",
        "Acompañamiento vía WhatsApp por 3 meses",
      ],
      popular: true, // Indicador de que es el plan más popular
    },
  ];

  const ModalInfo = (props) => {
    const [show, setShow] = useState(false);
    return (
      <div>
        <Button variant="link" className="mb-3" onClick={() => setShow(true)}>
          Descripción
        </Button>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container-fluid">{props.descripcion}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Volver
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  return (
    <section
      id="section_agenda"
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(8px)", // Aplicar difuminado a la imagen de fondo
          zIndex: 1,
        }}
      />
      <div
        className="container py-5"
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="row gy-4 justify-content-center">
          <div className="col-12">
            <h2 className="display-5 fw-bold text-center text-white">
              Nuestros servicios
            </h2>
          </div>

          {servicios.map((c, i) =>
            c.activo ? (
              <div
                key={i}
                className="col-lg-4 col-md-6 text-center p-3"
                style={
                  c.popular
                    ? {
                        ...cardStyle,
                        border: "2px solid #28a745",
                        backgroundColor: "#e8f5e9",
                        boxShadow: "none", // Evitamos doble sombra
                      }
                    : {}
                } // Resalta el pack popular sin aplicar doble sombra
              >
                <Fade direction="up" triggerOnce>
                  <div className="card shadow-sm">
                    <div className="card-body">
                      {c.popular && (
                        <span
                          style={{
                            backgroundColor: "#28a745",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            position: "absolute",
                            top: "-10px",
                            right: "10px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Más Popular
                        </span>
                      )}

                      <h5 className="fw-bold mb-3" style={titleStyle}>
                        {c.title}
                      </h5>
                      {c.subtitulo && (
                        <p style={subtituloStyle}>{c.subtitulo}</p>
                      )}
                      {c.descripcion && (
                        <ModalInfo key={i} descripcion={c.descripcion} />
                      )}

                      {/* Lista de beneficios */}
                      <ul style={beneficiosStyle}>
                        {c.beneficios.map((beneficio, index) => (
                          <li key={index} style={beneficioItemStyle}>
                            <span style={iconoStyle}>✔</span> {beneficio}
                          </li>
                        ))}
                      </ul>

                      {c.tiempo && <p style={tiempoStyle}>{c.tiempo}</p>}

                      {/* Precios por mes */}
                      <p className="h5 text-success" style={precioStyle}>
                        {c.precio_por_mes}
                      </p>

                      <div className="mt-4">
                        {c.tipo === "paciente_ficha" ? (
                          <ModalFicha />
                        ) : (
                          <ModalAgendar tipo={c.tipo} />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            ) : null
          )}
        </div>
      </div>
    </section>
  );
}

// Estilos personalizados
const cardStyle = {
  borderRadius: "15px",
  backgroundColor: "#ffffff",
  padding: "20px",
  transition: "transform 0.3s ease-in-out",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Mantener una sola sombra para los cards
};

const titleStyle = {
  color: "#343a40",
};

const subtituloStyle = {
  color: "#6c757d",
  fontSize: "1rem",
};

const tiempoStyle = {
  color: "#6c757d",
  fontSize: "0.9rem",
};

const precioStyle = {
  color: "#28a745",
  fontWeight: "bold",
};

const beneficiosStyle = {
  backgroundColor: "#EAF8E6", // Fondo verde claro
  padding: "10px",
  borderRadius: "10px",
  textAlign: "left",
  listStyleType: "none",
  marginBottom: "20px",
};

const beneficioItemStyle = {
  marginBottom: "8px",
  color: "#555",
};

const iconoStyle = {
  color: "#28a745", // Verde para el icono de verificación
  marginRight: "10px",
};

export default SectionAgenda;
