import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function PageFooter() {
  return (
    <Navbar className="shadow-top" bg="light" variant="light">
      <Container>
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} to="/terminos">
            Condiciones de uso
          </Nav.Link>
          <Nav.Link as={NavLink} to="/privacidad">
            Política de privacidad
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default PageFooter;
